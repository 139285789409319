import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import Fallback from "../Fallback";
import NotFound from "../404/NotFound";
import { clientOnboard } from "../../clients/Customers/Customers";

export default function VerifyUUID({ setCustData, children }) {
  const location = useParams();
  const [uuid, setUuid] = useState(null);
  const [data, setData] = useState(null);
  const params = useParams();
  useEffect(() => {
    // Verifying UUID
    if (!!!data) verifyUUID();
  }, []);

  const verifyUUID = async () => {
    clientOnboard
      .get("/campaign/customer/consent/limit")
      .then((resp) => {
        setCustData(resp.data);
        setData(resp.data);
        setUuid(params?.uuid);
      })
      .catch((e) => {
        setCustData(e?.response?.data);
        setData(e?.response?.data);
        setUuid(params?.uuid);
      });
  };

  if (uuid === null) return <Fallback />;

  if (uuid === undefined) return <NotFound />;

  if (data?.code === "INVALID_TOKEN") return <NotFound />;

  if (data?.code === "REQUEST_FAILED") return <NotFound />;
  if (
    data?.msg === "REDIRECT_CUSTOMERS_CONSENT" ||
    data?.msg === "CUSTOMERS_CONSENT_EXIST"
  )
    return children;

  return <></>;
}
