import axios from "axios";

const REACT_APP_42CS_AUTH_URL = process.env.REACT_APP_42CS_AUTH_URL;

const clientOnboard = axios.create({
  baseURL: REACT_APP_42CS_AUTH_URL,
});

clientOnboard.interceptors.request.use(async (config) => {
  let paths = String(window.location.pathname).split("/");
  let uuid = paths[paths.length - 1];
  config.headers["x-token"] = uuid;
  return config;
});

class Customers {
  static submitCustomerOnboard(data) {
    return new Promise((resolve, reject) => {
      clientOnboard
        .post(`/customer/onboard`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (!res || !res.data) {
            return reject(res);
          }

          return res;
        })
        .then((res) => res.data)
        .then(resolve)
        .catch(reject);
    });
  }

  static getCustomerOnboardOtp(data) {
    return new Promise((resolve, reject) => {
      clientOnboard
        .post(`/campaign/otp/send`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (!res || !res.data) {
            return reject(res);
          }

          return res;
        })
        .then((res) => res.data)
        .then(resolve)
        .catch(reject);
    });
  }

  static submitCustomerOnboardOtp(data) {
    return new Promise((resolve, reject) => {
      clientOnboard
        .post(`/campaign/otp/verify`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (!res || !res.data) {
            return reject(res);
          }

          return res;
        })
        .then((res) => res.data)
        .then(resolve)
        .catch(reject);
    });
  }
}

export { clientOnboard };

export default Customers;
