import Customers from "../clients/Customers/Customers";
import setCustomerOnboardOtpData from "../actions/setCustomerOnboardOtpData";
import startCustomerOnboard from "../actions/startCustomerOnboard";

const getCustomerOnboardOtpData = (data) => (dispatch) => {
  dispatch(startCustomerOnboard());

  return Customers.getCustomerOnboardOtp(data)
    .then((data) => {
      dispatch(setCustomerOnboardOtpData(data));
      return data;
    })
    .catch(console.log);
};

export default getCustomerOnboardOtpData;
