const timeline = {
  'timeline-event-see-details': 'See details',
  'timeline-event-status': 'status',
  'timeline-received-value': 'received value',
  'timeline-event-to': 'to',
  'timeline-event-from': 'from',
  'timeline-event-sent': 'sent',
  'timeline-event-discount-pismo': 'discount Pismo',
  'timeline-event-disputable': 'Report problem',
  'timeline-event-details': 'Details',
  'timeline-event-in-dispute': 'Transaction under review',
  'timeline-event-autentication': 'Authentication',
  'timeline-event-tid': 'TID',
  'message-transaction': 'Transaction accomplished more than 90 days',
  'timeline-event-authorization-id': 'Authorization',

  'timeline-event-payment-made': 'payment send',
  'timeline-event-payment-received': 'payment received',
  'timeline-event-payment-made-refused': 'payment sent refused',
  'timeline-event-payment-received-refused': 'payment received refused',

  'timeline-event-card-end': 'last digits',
  'timeline-event-transaction-goal': 'goal',
  'timeline-event-document': 'document',
  'timeline-event-document-person': 'document',
  'timeline-event-document-company': 'cnpj',
  'timeline-event-bank-company': 'institution',
  'timeline-event-bank-branch': 'bank branch',
  'timeline-event-bank-account': 'account',
  'timeline-payment-reason': 'finalidade',
  'timeline-transaction-id': 'transaction id',

  'timeline-event-barcode': 'Barcode',
  'timeline-event-status-category-CANCELLED': 'bankslip cancelled',
  'timeline-event-status-category-BANKSLIP': 'received via bankslip',
  'timeline-event-status-category-OVERDUE': 'overdue bankslip',
  'timeline-event-status-category-REGISTERED': 'bankslip created',
  'timeline-event-status-category-SETTLED': 'bankslip paid',
  'timeline-event-due-date-category-CANCELLED': 'cancelled on',
  'timeline-event-due-date-category-OVERDUE': 'overdue on',
  'timeline-event-due-date-category-REGISTERED': 'due date',
  'timeline-event-due-date-category-SETTLED': 'due date',

  'timeline-value': 'Value',
  'timeline-discount': 'Discount',
  'timeline-event-reason': 'reason',
  'timeline-event-DEBIT': 'debit',
  'timeline-event-DENIED': 'unrecognized transaction dispute denied',
  'timeline-event-CREDIT': 'credit',
  'timeline-event-TEMPORARY_ISSUER_LOSS': 'credit in trust',
  'timeline-event-CLOSED_APPROVED_TEMPORARY_ISSUER_LOSS':
    'confirmation of credit in trust',
  'timeline-event-ANALYSING': 'analysing',
  'timeline-event-OPEN': 'open',
  'timeline-event-CLOSED_REVERSED': 'reversal of credit in trust',
  'timeline-event-CLOSED_ISSUER_LOSS': 'credit to client',
  'timeline-event-payment-pismo': 'payment Pismo',
  'timeline-event-TRANSFER CREDITCARD':
    'Transfer by credit card sent to {to_name}',
  'event-REVERSAO BLOQUEIO JUDICIAL-CREDIT': 'Credit with Judicial Block',
};

export default timeline;
