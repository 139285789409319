export const onBoardingConfig = {
  CL_000CUB: {
    code: "CL_000CUB",
    name: `Dhi City Union Bank Visa Signature
    Credit Card`,
    welcome_message: `You are eligible for the DHI CUB Visa Signature Credit Card
    with a credit limit of RS {{LIMIT}}, would you like to confirm
    your request`,
    headerColor: "#000000",
    footerColor: "#000000",
    logo: require("../../assets/img/banner.png"),

    cssFile: "css/Cub.scss",
    terms_conditions_pdf_url: process.env.REACT_APP_CUB_TERMS_AND_CONDITIONS,
    tenantName: `Dhi City Union  Visa Signature Credit Card`,
  },
};

export const getConfig = (clientId) => {
  return !!onBoardingConfig[clientId];
};

export default onBoardingConfig;
