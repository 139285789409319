const general = {
  male: 'Male',
  female: 'Female',
  confirm: 'Confirm',
  confirmationMessage: 'Do you want to confirm this operation?',
  submitting: 'Submitting',
  submitted: 'Submitted',
  failedToSubmit: 'Failed to submit',
  creditcard: 'Credit card',
  credit: 'Credit',
  block: 'Block',
  unblock: 'Unblock',
  activate: 'Activate',
  statements: 'Statement',
  extract: 'Extract',
  profile: 'Profile',
  profileParams: 'Profile',
  cards: 'Cards',
  activity: 'Activity',
  notes: 'Notes',
  pid: 'Positive id',
  save: 'Save',
  cancel: 'Cancel',
  areYouSure: 'Enable travel mode / Disable travel mode',
  'general.update.success': 'Successfully updated data',
  'general.update.fail': 'Data update failed',
  'general.form.dirty.confirm':
    'The form has modifications that have not been saved. Do you want to continue without saving?',
  'general.onCall.exit.confirm':
    'You are on a call. Do you really want to close the current call and proceed to exit?',
  home: 'Home',
  mobileSummary: 'Home',
  'general.endCall': 'End call',
  available: 'Available',
  totalAvailable: 'Available Credit',
  availableBalance: 'Available Balance',
  over: 'over',
  overdraft_limit: 'Overdraft limit',
  minimum: 'Minimum payment',
  pay: 'Pay',
  recharge: 'Recharge',
  'general.dispute': 'Dispute',
  'general.pid': 'Positive Identification',
  current: 'Current',
  now: 'now',
  transaction: 'Transaction',
  minimumPayment: 'Minimum payment',
  maximumPayment: 'Maximum payment',
  tryAgainLater: 'Please, try again later.',
  open: 'current',
  closed: 'Closed',
  settled: 'Settled',
  rejected: 'Rejected',
  cancelled: 'Cancelled',
  processed: 'Processed',
  refunded: 'Refunded',
  refused: 'Refused',
  paid: 'Paid',
  for: 'for',
  to: 'to',
  dueOn: 'Due on ',
  programTypeNotFound: 'Program type not found',
  days: 'Days',
  all: 'All',
  back: 'Back',
  year: 'year',
  years: 'years',
  denied: 'Denied',
  MDR: 'tax',
  date: 'Date',
  noTransactionsFound: 'No transactions found.',
  protocol: 'Protocol',
  'general.address': 'Address',
  'general.mainAddress': 'Main address',
  'general.additionalAddress': 'Additional address',
  installments: 'Paid in {installments} installments',
  over_balance: 'Balance',
  'general.pagination.previous': 'Previous',
  'general.pagination.next': 'Next',
  'general.pagination.page': 'Page',
  'general.pagination.of': 'of',
};

export default general;
