const profileParameters = {
  'profile.avaliable': 'Available credit',
  'profile.minimum_payment': 'Minimum',
  'profile.minimum_payment_short': 'Min',
  'profile.due_date': ' Due date',
  'profile.MDR': 'Pismo tax',
  'profile.CASHBACK': 'Cashback',
  'profile.DESCONTO PISMO': 'Discount Pismo',
  'profile.delay': 'Payment overdue',
  'profile.best_transaction_day': 'Best day to purchase',
  'profile.credits': 'Points',
  'profile.monthly': 'Limit per installment',
  'profile.change': 'Update',
  'profile.pay': 'Pay',
  'profile.current_balance': 'Current balance',
  'profile.next_invoice': 'Next invoice',
  'profile.current_invoice': 'Current statement',
  'profile.menu.cards': 'Cards',
  'profile.menu.data': 'Profile',
  'profile.menu.spendingLimits': 'Spending Limits',
  'profile.menu.limits': 'Limits',
  'profile.menu.account': 'Account',
  'profile.status.NORMAL': 'Normal',
  'profile.status.CANCELLED': 'Cancelled',
  'profile.status.BLOCKED': 'Blocked',
  'profile.parameter.10': 'Tax post office extract',
  'profile.parameter.24271': 'Annuity value',
  'profile.parameter.28871': 'Annuity value',
  'profile.parameter.24272': 'Annuity parcel',
  'profile.parameter.24273': 'Tax late bill',
  'profile.parameter.24274': 'Tax Overlimit',
  'profile.parameter.24270': 'Tax e-mail extract',
  'profile.parameter.28870': 'Tax e-mail extract',
  'profile.limitProposal': 'Limit update',
  'profile.creditLimit': 'Credit limit',
  'profile.currentLimit': 'Current limit',
  'profile.pendingLimitProposal':
    'There is already a pending {br} credit limit proposal of ',
  'profile.parameter.success': 'Saved successfully',
  'profile.limitProposal.selectReason': 'Update reason',
  'profile.limitProposal.reasons.altIncome': 'New source of income',
  'profile.limitProposal.reasons.income': 'Salary increase',
  'profile.limitProposal.reasons.retirement': 'Retirement',
  'profile.limitProposal.reasons.spending': 'Spending more',
  'profile.limitProposal.reasons.trip': 'Trip',
  'profile.limitProposal.reasons.other': 'Other',
  'profile.limit.outcome.failure':
    'Limit change failed, please try again later.',
  'profile.limit.outcome.success': 'Limit was changed successfully.',
  'profile.limitProposal.exceedMaxLimitDisclaimer':
    'This value exceeds the pre-approved maximum amount. If you wish to send a request for analysis, please pick a reason below.',
  'profile.recharge': 'Recharge',
  'profile.account_status': 'Account status',
  'profile.account_status.FULL_CANCELLATION': 'Account closing',
  'profile.account_status.USER_CANCELLATION': 'User cancellation',
  'profile.account_status.ACCOUNT_CANCELLATION': 'Blocked',
  'profile.account_status.ACCOUNT_BLOCK': 'Account Block',
  'profile.account_status.JUDICIAL_BLOCK': 'Block',
  'profile.account_status.JUDICIAL_UNLOCK': 'Unlock',
  'profile.account_status.NORMAL': 'Active',
  'profile.account_status.ACTIVE': 'Active',
  'profile.account_status.DORMANT': 'Dormant',
  'profile.account_status.LOADING': 'Loading...',
  'profile.changeStatus': 'Update status',
  'profile.changeStatus.successTitle': 'Success',
  'profile.changeStatus.successMessage': 'Account status update succeed',
  'profile.changeStatus.rejectedTitle': 'Rejected',
  'profile.changeStatus.rejectedMessage': 'Account status update rejected',
  'profile.limits.noLimits': 'No limits',
  'profile.limits.noBankSlipLimits': 'No bank slip limits',
  'profile.limits.noPaymentRequests': 'No payment requests',
  'profile.age': 'Age',
  'profile.account_number': 'Account number',
  'profile.client_since': 'Account since',
  'profile.password_status': 'Password Status',
  'profile.password_status_history': 'History',
};

export default profileParameters;
