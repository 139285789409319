const cards = {
  'cards.types.regular': 'Additional',
  'cards.types.subscription': 'Subscription',
  'cards.types.recurrence': 'Recurrence',
  'cards.types.ecommerce': 'E-commerce',
  'cards.types.PLASTIC': 'Physical',
  'cards.types.virtual': 'Virtual',
  'cards.types.physical': 'Physical',
  'cards.types.VIRTUAL': 'Virtual Card',
  card: 'Card',
  'cards.types.TEMPORARY': 'E-commerce',
  'cards.types.CARD_ON_FILE': 'Recurrence',
  'cards.types.RECURRING': 'Subscription',

  'cards.form.newCard': 'New card',
  'cards.form.holderName.placeholder': "User's full name *",
  'cards.form.printedName.placeholder': 'Printed name *',
  'cards.form.birthdate.placeholder': 'Birth date (ex.: 04/11/1986) *',
  'cards.form.gender.placeholder': 'Gender *',
  'cards.form.submit': 'Submit',

  'cards.params.verification': 'Verified',
  'cards.params.not_verificated': 'Not verified',
  'cards.params.effective': 'Effective',
  'cards.params.printed_name': 'User',
  'cards.params.stage': 'Additional status',
  'cards.params.status': 'Status',
  'cards.params.issuing_date': 'Issue date',
  'cards.params.name': 'Card name',
  'cards.params.type': 'Type',
  'cards.params.transaction_limit': 'Limit per transaction',
  'cards.params.expiration_date': 'Expiration date',
  'cards.params.network': 'Brand',
  'cards.params.last_4_digits': 'Card',
  'cards.params.creation_date': 'Creation date',
  'cards.params.issuer_card': 'Pismo card',
  'cards.params.card_profile': 'Color',
  'cards.params.card_name': 'Nickname',
  'cards.params.document_number': 'Document Number',
  'cards.params.true': 'Yes',
  'cards.params.false': 'No',
  'cards.params.INOPERATIVE': 'Inoperative',

  'cards.params.CREATED': 'Created',
  'cards.params.ACTIVE': 'Active',
  'cards.params.SENT': 'Sent',
  'cards.params.UNBLOCKED': 'Unblocked',
  'cards.params.SUSPENDED': 'Suspended',
  'cards.params.PENDING': 'Pending',
  'cards.params.POSTED': 'Posted',
  'cards.params.TRANSFER': 'Transfer',
  'cards.params.REDIRECT_BY_THIRD': 'Redirect by third',
  'cards.params.DELIVERED_BY_THIRD': 'Delivered by third',
  'cards.params.RETURNED_BY_THIRD': 'Returned by third',
  'cards.params.DELIVERY_IN_PROGRESS': 'Delivered in progress',
  'cards.params.NOT_DELIVERED': 'Not delivered',
  'cards.params.ARRIVED': 'Arrived',
  'cards.params.UNRECEIVED': 'Unreceived',
  'cards.params.REGISTERED': 'Registered',
  'cards.params.CUSTODY': 'Custody',
  'cards.params.DESTRUCTION': 'Destruction',
  'cards.params.ENABLE_RESEND': 'Enable resend',

  'cards.params.NORMAL': 'Normal',
  'cards.params.BLOCKED': 'Blocked',
  'cards.params.CANCELED': 'Canceled',
  'cards.params.DAMAGED': 'Damaged',
  'cards.params.EXPIRED': 'Expired',
  'cards.params.FRAUD': 'Fraud',
  'cards.params.LOST': 'Lost',
  'cards.params.ROBBED': 'Stolen',
  'cards.params.WARNING': 'Warning',
  'cards.params.REISSUED': 'Reissued',
  'cards.params.DELETED': 'Deleted',
  'cards.params.BROKEN': 'Broken',
  'cards.params.SHAVED': 'Shaved',
  'cards.params.RENAME': 'Rename',
  'cards.params.THEFT': 'Theft',
  'cards.params.UNBOUND': 'Unbound',
  'cards.params.UNRECEIVED': 'Unreceived',

  'cards.changeStatus': 'Update status',
  'cards.unblockTitle': 'Are you sure?',
  'cards.statusChangeTitle': 'Choose a status change reason',

  'cards.newCard': 'Resend new card',
  'cards.newCard.title': 'Resend new card',
  'cards.newCard.addressTitle': 'Mailing address',
  'cards.newCard.reason': 'Update reason',
  'cards.newCard.selectReason': 'Select a reason',
  'cards.newCard.costExplanation': 'For this reason there will be a charge',
  'cards.newCard.cvvExplanation':
    'The CVV of the card will change after 180 days for security reasons',

  'cards.selectStatus': 'Choose a status',
  'cards.statuses.BLOCKED': 'Blocked',
  'cards.statuses.CANCELED': 'Canceled',
  'cards.statuses.DAMAGED': 'Damaged',
  'cards.statuses.EXPIRED': 'Expired',
  'cards.statuses.FRAUD': 'Fraud',
  'cards.statuses.LOST': 'Lost',
  'cards.statuses.NORMAL': 'Normal',
  'cards.statuses.ROBBED': 'Stolen',
  'cards.statuses.WARNING': 'Warning',
  'cards.statuses.REISSUED': 'Reissued',
  'cards.statuses.DELETED': 'Deleted',
  'cards.statuses.BROKEN': 'Broken',
  'cards.params.OVERDUE': 'Overdue',
  'cards.statuses.LOSS': 'Loss',
  'cards.statuses.CLIENT_ORDER': 'Client order',
  'cards.statuses.DEFECT': 'Defect',
  'cards.statuses.INCORRECT_NAME': 'Incorrect name',
  'cards.statuses.RELIEF_LOSS': 'Relief loss',
  'cards.statuses.RENAME': 'Rename',
  'cards.statuses.SHAVED': 'Shaved',
  'cards.statuses.THEFT': 'Theft',
  'cards.statuses.UNBOUND': 'Unbound',
  'cards.statuses.UNRECEIVED': 'Unreceived',

  'card.delete': 'Delete card',
  'cards.STRIKETHROUGH': 'Strikethrough',
  'cards.notReceived': 'Card not received',
  'cards.notWorking': 'Card not working',
  'cards.isDefective': 'Card is defective',
  'cards.reliefLoss': 'Relief loss',
  'cards.nameChange': 'Name change',
  'cards.userRequest': 'User request',

  'card.seeAccount': 'See account',
  'card.physical': 'Physical',
  'card.virtual': 'Virtual',
  'cards.deleteCard.confirm': "Once deleted, the card can't be recovered.",
  'cards.newCard.delete.failure': 'Failed to delete the card.',
  'cards.newCard.delete.success': 'Card deleted successfully.',
  'cards.newCard.success': 'New card generated successfully!',
  'cards.newCard.failure': 'There was a problem generating your new card.',
  'cards.newCard.cardCreatedCvvExplanation':
    'New card generated successfully. The CVV of the card will change after 180 days for security reasons',
  'cards.unblock.success': 'You card is now unblocked!',
  'cards.unblock.failure':
    "There was a problem and we couldn't unblock your card.",
  'cards.statusChange.success': 'Card status was successfully changed!',
  'cards.statusChange.failure': 'Failed to change card status.',

  'cards.definitiveBlock': 'Definitive block',
  'cards.temporaryBlock': 'Temporary block ',
  'cards.temporaryBlock.title': 'Confirm card blocking.',
  'cards.temporaryBlock.success': 'Card was successfully blocked',
  'cards.temporaryBlock.failure': 'Failed to block the card.',

  'cards.temporaryUnblock': 'Unblock',
  'cards.temporaryUnblock.title': 'Confirm card unblocking.',
  'cards.temporaryUnblock.success': 'Card was successfully unblocked!',
  'cards.temporaryUnblock.failure': 'Failed to unblock the card.',

  'cards.editParam': 'Edit card parameter',
  'cards.editParam.successSingular': 'was successfully saved!',
  'cards.editParam.successPlural': 'were successfully saved!',
  'cards.editParam.failure': 'Failed to save the edited card.',

  'cards.revealData.tooltip.title': 'Card information',
  'cards.revealData.tooltip': 'Click to display or hide sensitive data.',
  'cards.noCards': 'No cards found',
  'cards.noCardsAccount': 'No account found related to this card',

  'cards.sensitiveInfo.show': 'Show data',
  'cards.sensitiveInfo.hide': 'Hide data',

  'cards.newPin': 'New PIN',
  'cards.newPinConfirmation': 'New PIN (confirmation)',
  'cards.pinMatchError': 'Pin and Confirm Pin does not match',
  'cards.emptyPinError': 'Required field',
  'cards.pinChange': 'Change card PIN',
  'cards.pinChange.success': 'PIN successfully changed!',
  'cards.pinChange.failure': 'Failed to change PIN.',
  'cards.pinMinlengthError': 'Pin length should be 4 digits',
};

export default cards;
