const initialState = {
  root: {},
  onBoard: {
    isLoading: false,
    hasError: false,
    data: {},
    otpData: {},
    account: {},
    clientId: null,
  },
};

export default initialState;
