import { LinearProgress } from "@material-ui/core";
import React from "react";
export const Fallback = React.memo(() => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ width: "250px" }}>
        <LinearProgress />
      </div>
    </div>
  );
});

export default Fallback;
