import validateReactIntlDictKeys from "./validateReactIntlDictKeys";

import en from "./en";

if (!validateReactIntlDictKeys([en])) {
  throw new Error("Confira as keys dos dicionarios de i18n.");
}

const i18n = {
  en,
};

export default i18n;
