import { combineReducers } from "redux";

import { default as onBoard } from "./onBoard";
// import initialState from "../store/initialState";

function deriveObjectWithKeys(obj, keys) {
  const newObj = {};

  keys.forEach((propertyName) => {
    newObj[propertyName] = { ...obj[propertyName] };
  });

  return newObj;
}

const root = (state = {}, action) => {
  if (action) {
    return state;
  }
};

const appReducer = combineReducers({
  root,
  onBoard,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
