import React from "react";
import { useSelector } from "react-redux";
import "./BrandStyles.scss";
import onBoardingConfig from "../utils/onBoarding/OnboardingConfig";
const BrandProvider = (props) => {
  const clientId = onBoardingConfig.CL_000CUB.code;
  return <div className={clientId}>{props.children}</div>;
};

export default BrandProvider;
