import React from "react";
import { onBoardingConfig } from "../../utils/onBoarding/OnboardingConfig";
import "./NotFound.scss";

export default function NotFound() {
  return (
    <section className="onboard-root">
      <div className="onboard-wrapper">
        <div className="onboard-invalid-link">
          <div>
            <h1 className="onboard-invalid-text"></h1>
          </div>
          <div className="onboard-invalid-page-title">
            Invalid or Expired Link !!!
          </div>
          <div className="onboard-banner-img">
            <img
              alt="Cub Logo"
              src={onBoardingConfig.CL_000CUB.logo.default}
              className="onboard-invalid-img"
            />
          </div>
        </div>
        <div></div>
      </div>
    </section>
  );
}
