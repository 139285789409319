import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect, Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import configureStore from "../store";
import i18n from "../i18n";

import "./App.scss";
import "currency-flags/dist/currency-flags.min.css";

import BrandProvider from "./BrandProvider";
import { CustomerOnBoard } from "../pages/CustomerOnboard";
import onBoardingConfig from "../utils/onBoarding/OnboardingConfig";
import NotFound from "../components/404/NotFound";

const store = configureStore();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogin: true,
    };
  }

  componentDidMount() {
    sessionStorage.setItem("clientId", onBoardingConfig?.CL_000CUB?.code);
  }

  render() {
    return (
      <IntlProvider locale={"en"} messages={i18n["en"]}>
        <BrandProvider>
          <div className="App__Inner">
            <Router>
              <Switch>
                <Route
                  exact
                  path={"/customer-consent/:uuid"}
                  component={CustomerOnBoard}
                />
                <Route path="*" component={NotFound} />
              </Switch>
            </Router>
          </div>
        </BrandProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state, props) => ({
  ...state,
  ...props,
});

const ConnectedApp = connect(mapStateToProps)(App);

class AppWrapper extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedApp />
      </Provider>
    );
  }
}

export default AppWrapper;
